<template>
  <rpm-layout>
    <div class="access-denied">
      <h1>Access Denied</h1>
      <p>You do not have permission to view this page.</p>
    </div>
  </rpm-layout>
</template>

<script>
import RpmLayout from "@/layouts/RpmLayout.vue";

export default {
  name: 'AccessDenied',
  components: {RpmLayout},
  data() {
    return {
      accessToken: null,
    };
  },
  created: async function () {
    await this.getAccessToken();
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser') : null,
    ]).finally(() => {

    });
  },
  methods: {
    async getAccessToken() {
      this.accessToken = await this.$auth.getTokenSilently();
    },
  },
};
</script>

<style scoped>
.access-denied {
  text-align: center;
  margin-top: 50px;
}
</style>